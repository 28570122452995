import { cva } from "class-variance-authority";

const root = cva([
  "z-50",
  "fixed inset-0 dark:bg-secondary/80 bg-black/40",
  "data-[state]:duration-100",
  "data-[state=open]:animate-in data-[state=open]:fade-in-0",
  "data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
]);

export const overlay = { root };
