import * as DialogPrimitive from "@radix-ui/react-dialog";
import { forwardRef } from "react";
import { cn } from "../utils";
import { Text } from "../text/text";
import { overlay } from "../overlay/overlay.styles";
import { XIcon } from "lucide-react";

export const Dialog = DialogPrimitive.Root;

export const DialogTrigger = DialogPrimitive.Trigger;

export const DialogClose = DialogPrimitive.Close;

interface DialogContentProps extends DialogPrimitive.DialogContentProps {
  hideClose?: boolean;
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  ({ className, children, hideClose, ...props }, ref) => {
    return (
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className={cn(overlay.root())} onClick={(e) => e.stopPropagation()} />
        <DialogPrimitive.Content
          ref={ref}
          {...props}
          onClick={(e) => e.stopPropagation()}
          className={cn(
            [
              "fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%]",
              "bg-background grid w-full max-w-lg gap-3 rounded-lg p-6 shadow-xl shadow-black/5 ring-1 ring-black/5",
              "data-[state]:duration-100",
              "data-[state=open]:animate-in data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=closed]:fade-out-0",
              "data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] data-[state=open]:fade-in-0",
            ],
            className,
          )}
        >
          {children}
          {!hideClose ? (
            <DialogClose className="text-contrast/25 absolute right-4 top-4">
              <XIcon className="size-4" />
            </DialogClose>
          ) : null}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    );
  },
);

DialogContent.displayName = DialogPrimitive.Content.displayName;

export const DialogTitle: typeof DialogPrimitive.Title = forwardRef(({ ...props }, ref) => {
  return (
    <DialogPrimitive.Title {...props} ref={ref} asChild>
      <Text as="span" color="contrast" weight="medium" className="leading-6">
        {props.children}
      </Text>
    </DialogPrimitive.Title>
  );
});

DialogTitle.displayName = DialogPrimitive.Title.displayName;

export const DialogDescription: typeof DialogPrimitive.Description = forwardRef(({ ...props }, ref) => {
  return (
    <DialogPrimitive.Description {...props} ref={ref} asChild>
      <Text as="span" color="content" size="sm">
        {props.children}
      </Text>
    </DialogPrimitive.Description>
  );
});

DialogDescription.displayName = DialogPrimitive.Description.displayName;

export const DialogFooter = ({ className, ...props }: { children: React.ReactNode; className?: string }) => {
  return <div className={cn("flex justify-end space-x-2 pt-4", className)} {...props} />;
};

DialogFooter.displayName = "DialogFooter";
